<template>
  <section>
      <div v-if="!showForm" class="suggest-button" @click="toggleForm">
        <Idea class="idea" /> Sugerir Funcionalidade
      </div>
      <div v-else class="suggest-form">
        <div class="header">
          <div class="suggest-title">Sugerir Funcionalidade</div>
          <Icon tooltip="Fechar formulário">
            <Close class="close"  @click="toggleForm" />
          </Icon>
        </div>
        <div class="suggest-subtitle">O que podemos fazer para ser mais útil para você?</div>
        <b-form-group>
          <label>Título</label>
          <b-input 
            type="text" 
            placeholder="Sua ideia"
            v-model="form.title"
          />
          <div v-if="validated && !form.title" class="custom-invalid-feedback">Campo obrigatório</div>
        </b-form-group>
        <b-form-group>
          <label>
            Detalhes
          </label>
          <b-form-textarea
            style="height: auto;"
            rows="6" 
            placeholder="Faça um resumo de forma objetiva do que gostaria de ver no sistema." 
            v-model="form.details"
          ></b-form-textarea>
          <div v-if="validated && !form.details" class="custom-invalid-feedback">Campo obrigatório</div>
        </b-form-group>
        <div class="submit-wrapper">
            <b-button 
                variant="primary"
                :disabled="loading"
                @click="onSubmit"
            >
                Enviar
            </b-button>
        </div>
      </div>
    </section>
</template>

<script>
import api from '../../api'
import { getCurrentUser } from '@/utils/localStorageManager'
export default {
    components: {
        Icon: () => import('@/components/General/Icon'),
        Close: () => import('@/assets/icons/close.svg'),
        Idea: () => import('@/assets/icons/idea.svg'),
    },
    data() {
        return {
            loading: false,
            showForm: false,
            validated: false,
            form: this.getDefaultForm(),
        }
    },
    methods: {
        getDefaultForm() {
            const user = getCurrentUser()
            return {
                person_id: user?.id,
                person: { name: user?.name },
                title: '',
                details: '',
                status: 'under-review',
                upvotes: 0,
                has_upvoted: false
            }
        },
        toggleForm() {
            this.showForm = !this.showForm
            this.form = this.getDefaultForm()
        },
        isValid() {
            this.validated = true
            return this.form.title && this.form.details
        },
        onSubmit() {
            if (!this.isValid()) return
            this.loading = true
            api.createFeatureRequests(this.form)
            .then(({ data }) => {
                this.form = this.getDefaultForm()
                this.toggleForm()
                this.$emit('onCreated', {...this.form, ...data })
                this.$toast.success('Obrigado! Sua sugestão foi enviada para revisão.')
            })
            .catch((err) => {
                console.log(err.message)
                this.$toast.error('Ocorreu um erro ao enviar sua sugestão!')
            })
            .finally(() => (this.loading = false))
        }
    },
}
</script>

<style lang="scss" scoped>
.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.suggest-button {
  display: flex;
  padding: 1rem;
  border-radius: 2px;
  background-color: #ececec;
  box-shadow: none;
  cursor: pointer;
  color: var(--blue-500);
  font-weight: 600;
}
.suggest-form {
  padding: 1rem;
  border-radius: 2px;
  background-color: #ececec;
  border-radius: 4px;
}
.submit-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
.suggest-title {
  color: #424242;
  font-size: 1.2rem;
}
.suggest-subtitle {
  color: #424242;
  font-size: 1rem;
  margin-bottom: 1rem;
}
.close {
  width: 25px;
  fill: var(--neutral-500);
}
.idea {
  width: 25px;
  fill: var(--blue-500);
}
</style>